@use "styles" as *;

.headerMain {
  background: $white;
  @include for-size(desktop) {
    position: sticky;
    top: 0;
    z-index: 9;
  }
  @include for-size(phone) {
    background: $pharma7;
    @include flex(center, flex-start, row);
    padding: 10px 0 0;
    height: initial;
  }
  .borderBtm {
    @include for-size(desktop) {
      border-bottom: 1px solid $grey3;
    }
    @include for-size(phone) {
      width: 100%;
    }
  }
  .topHeader {
    @include flex(center, "", row);
    @include for-size(desktop) {
      height: 60px;
      padding: 6px 0;
    }
    .headLeft {
      @include flex(center, flex-start, row);
      height: 48px;
      order: 1;
      @include for-size(phone) {
        width: 50%;
      }
      .logoMain {
        margin-right: 26px;
      }
      .locationRoot {
        @include for-size(desktop) {
          width: 180px;
        }
      }
    }
    .searchPlaceholder {
      order: 2;
      @include for-size(phone) {
        order: 3;
        padding: 12px 6px;
        @include flex(center, space-between, row);
        .cartIconForMweb {
          position: relative;
          height: 48px;
          width: 48px;
          flex-shrink: 0;
          background: rgba(255, 255, 255, 0.1);
          @include border-radius(8px);
          margin-left: 16px;
          @include flex(center, center, row);
          .cartIco {
            font-size: 30px;
            &::before {
              color: #fafbf9;
            }
          }
        }
      }
    }

    .headRight {
      margin-left: auto;
      width: auto;
      order: 3;
      @include for-size(phone) {
        order: 2;
      }

      .headNavBx {
        @include flex(center, flex-end, row);
        gap: 8px;
        @include for-size(phone) {
          &:last-child {
            gap: 0;
          }
        }
        .nav {
          position: relative;
          @include flex(center, center, row);
          &:first-child {
            margin-left: 0;
          }
          &.signedinProfile {
            @include for-size(desktop) {
              width: 48px;
              height: 48px;
            }
          }
          @include for-size(phone) {
            width: 48px;
            height: 48px;
            padding: 0;
            &:hover,
            &.active {
              background: rgba(255, 255, 255, 0.1);
              @include border-radius(8px);
            }
          }

          .icon {
            height: 48px;
            width: 48px;
            @include flex(center, center, row);
            cursor: pointer;
            position: relative;
            flex-shrink: 0;
            @include for-size(phone) {
              height: 24px;
              width: 24px;
            }
            &.cart {
              font-size: 30px;
            }
            &.bell {
              -webkit-text-stroke: 0.7px;
            }
            &.userIcon {
              height: 24px;
              width: 24px;
              background: $grey1;
              &::before {
                color: $pharma6;
              }
            }
            &::before {
              color: $grey10;
              @include for-size(phone) {
                color: #fafbf9;
              }
            }
          }
          .cartNofify {
            position: absolute;
            background: #ff748e;
            display: inline-block;
            line-height: 14px;
            font-size: 8px;
            font-weight: 700;
            color: $white;
            text-align: center;
            @include border-radius(50%);
            @include text-overflow(ellipsis);
            @include for-size(desktop) {
              width: 14px;
              height: 14px;
              right: 8px;
              top: 8px;
            }
            @include for-size(phone) {
              height: 20px;
              max-width: 48px;
              right: -10px;
              top: 0;
            }
            &.hcNoti {
              background: #0e3736;
              @include border-radius(10px);
              padding: 4px;
              min-width: 27px;
              @include text-overflow(ellipsis);
              display: inline-block;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .navRoot {
    height: 40px;
    @include flex(stretch, space-between, row);
    padding: 10px 0 4px;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    .list {
      position: relative;
      @include transition(all 2s ease-in-out);
      > a {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        color: $black;
        > div {
          margin-left: 2px;
        }
      }
      &:hover,
      &.active {
        &::before {
          content: "";
          background: $pharma6;
          height: 2px;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          @include border-radius(4px);
        }
        > a {
          color: $pharma6;
        }
      }
    }
  }
  .notify {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 20px;
    min-width: 20px;
    max-width: 40px;
    @include border-radius(50%);
    background: $pharma9;
    color: $white;
    font-size: 10px;
    font-weight: 700;
    line-height: 17px;
    @include text-overflow(ellipsis);
    display: inline-block;
    text-align: center;
    padding: 2px 4px;
  }
  &.locationUp {
    @include for-size(desktop) {
      z-index: 12;
    }
    .navRoot {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.headerMainHide {
  @include for-size(phone) {
    display: none;
  }
}
.logo {
  width: 70px;
  height: 48px;
  @include flex(center, center, row);
  flex-shrink: 0;
  cursor: pointer;
  > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.loginCta {
  @include flex(center, flex-start, row);
  @include border-radius(8px);
  border: 1px solid $pharma6;
  margin-left: 10px;
  cursor: pointer;
  height: 40px;
  padding: 0 12px 0 16px;
  min-width: 103px;
  @include for-size(phone) {
    background: rgba(255, 255, 255, 0.1);
  }
  .loginCTATxt {
    font-weight: 600;
    margin-right: 12px;
    @include for-size(desktop) {
      color: $pharma6;
      font-size: 14px;
    }
    @include for-size(phone) {
      color: $white;
      font-size: 12px;
    }
  }
}
.icon-language:before {
  content: "\e789";
  cursor: pointer;
  user-select: none;
}
.languageWholeWrap {
  z-index: 8;
  position: fixed;
  inset: 0;
}
.languageWrap {
  width: 101px;
  max-height: 207px;
  z-index: 9;
  font-weight: 400;
  background: $white;
  position: absolute;
  top: 32px;
  left: 0;
  overflow-y: auto;
  @include scrollbars(4px);
  .unorderedList {
    .listitem {
      @include flex(center, flex-start, row);
      word-break: break-word;
      min-height: 40px;
      padding: 4px 16px;
      &:hover,
      &.active {
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
.languageRoot {
  @include for-size(desktop) {
    min-width: 104px;
  }
  cursor: pointer;
  .languageIcon {
    margin-right: 10px;
  }
  .selectedLanguage {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    width: 70px;
    @include text-overflow(ellipsis);
    padding-right: 12px;
    position: relative;
    .arrowIco {
      margin-left: 8px;
      font-size: 8px;
      position: absolute;
      right: 0;
      top: 8px;
    }
  }
}

.ssrlanguageWrapHide {
  visibility: hidden;
}
.languageAnchor {
  width: 100%;
}
