@use "styles" as *;

.unservicable {
  position: relative;
  z-index: 9;

  .backbrop {
    position: fixed;
    z-index: 1;
    user-select: none;
    inset: 0;
    bottom: 78px;
    background: rgba($grey10, 0.5);
  }
  .topSection {
    padding: 12px;
    z-index: 2;
    top: 60px;
    left: 25px;
    max-width: 300px;
    width: 100%;
    position: fixed;
    @include border-radius(12px);
    background: $white;
    @include box-shadow(0px 2px 8px 0px rgba(190, 190, 187, 0.6));
    &:before {
      content: "";
      position: absolute;
      height: 12px;
      width: 12px;
      background-color: $white;
      transform: rotate(45deg);
      top: -5px;
      left: 16px;
    }
    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .subTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 12px;
      color: $grey9;
    }
    .btn {
      margin: 4px 0 0 auto;
      background: $grey10;
      columns: $white;
    }
  }
}
.overflowHidden {
  overflow: hidden !important;
}
