@use "styles" as *;

.errorScreenRoot {
  min-height: 300px;
  max-width: 1100px;
  @include for-size(phone) {
    padding: 0 32px;
  }
  .centerDiv {
    margin: 0 auto;
  }
  .leftSection {
    @include for-size(phone) {
      order: 2;
    }
    .infoSection {
      @include for-size(desktop) {
        width: 320px;
      }
      @include for-size(phone) {
        @include flex(center, center, column);
        text-align: center;
      }
      .title {
        font-weight: 700;
        margin-bottom: 16px;
        @include for-size(desktop) {
          font-size: 42px;
          line-height: 60px;
          margin-bottom: 16px;
        }
        @include for-size(phone) {
          font-size: 32px;
          line-height: 44px;
        }
        > span {
          display: block;
        }
      }
      .subTitle {
        @include for-size(desktop) {
          font-size: 18px;
          line-height: 26px;
        }
        @include for-size(phone) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .ctaBtn {
        justify-content: center;
        @include for-size(desktop) {
          margin-top: 32px;
        }
        @include for-size(phone) {
          margin-top: 40px;
        }
        @include for-size(desktop) {
          width: 100%;
        }
      }
    }
  }

  .imageBx {
    text-align: center;
    order: 2;
    > img {
      @include imageResponsive;
    }
    @include for-size(phone) {
      order: 1;
      margin-bottom: 40px;
      > img {
        max-width: 240px;
        height: 282px;
        object-fit: contain;
        margin: 0 auto;
      }
    }
  }
}
