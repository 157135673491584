@use "styles" as *;

.sRoot {
  width: 100%;
  border: 1px solid #e7e7e7;
  @include border-radius(8px);
  padding: 10px 16px;
  @include flex(center, flex-start, row);
  background: #f7f7f7;
  overflow: hidden;
  font-weight: 400;
  letter-spacing: -0.2px;
  @include for-size(desktop) {
    height: 40px;
    font-size: 16px;
    color: #808080;
    gap: 10px;
  }
  @include for-size(phone) {
    height: 48px;
    font-size: 14px;
    color: #aaa;
    gap: 8px;
  }
}
.icon {
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  @include flex(center, center, row);
}

.icon-searchIcon:before {
  content: "\eb95";
  color: #1a504c;
}
