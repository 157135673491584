@use "styles" as *;

.savedAddressChild {
  @include border-radius(12px);
  border: 1px solid $grey3;
  background-color: $white;
  margin-bottom: 12px;
  cursor: pointer;
  @include for-size(desktop) {
    padding: 24px 16px;
  }
  @include for-size(phone) {
    padding: 16px;
  }
  .addressType {
    @include flex(center, space-between, row);
    margin-bottom: 16px;
    .heading {
      @include flex(center, flex-end, row);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      .icon {
        font-size: 18px;
        flex-shrink: 0;
        margin-right: 8px;
        height: 20px;
        width: 20px;

        &::before {
          color: $grey10;
        }
      }
    }
  }
  .addressDesc {
    @include line-clamp(2);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: $grey8;
  }
  .iconSection {
    display: flex;
  }
}
.sentenceCase {
  text-transform: capitalize;
}
