@use "styles" as *;

.searchSection {
  position: relative;
  .searchBlock {
    position: relative;
    height: 48px;
    margin: 10px 0;
    @include flex(center, space-between, row);
    padding: 4px 16px 4px 4px;
    border-radius: 12px;
    border: 1px solid $grey8;
    background: $grey1;
    .inputSearchIcon {
      width: 85%;
      input {
        height: 46px !important;
        border: none !important;
      }
    }
    .ico {
      height: 24px;
      width: 24px;
      @include border-radius(50%);
      overflow: hidden;
      border: 2px solid $grey10;
      font-size: 16px;
      @include flex(center, center, row);
      &::before {
        color: $grey10;
      }
    }
  }
  .searchItemListHead {
    padding: 0 0 0 36px;
    position: relative;
    cursor: pointer;
    .searchResult {
      @include font-weight(400);
    }
    h3 {
      margin: 0;
      color: #fcb716;
      font-size: 16px;
      line-height: 24px;
      @include font-weight(700);
    }
    h5 {
      margin: 0;
      color: #fcb716;
      font-size: 12px;
    }
    img {
      position: absolute;
      left: 0;
      top: 5px;
      overflow: hidden;
    }
  }
}

.progressLoader {
  text-align: left;
  padding: 20px;
}

.locationAutoComplete {
  text-align: left;
  border: none;
  background: $white;
}
.addressListRoot {
  margin: 0;
  padding: 0;
  .list {
    cursor: pointer;
    padding: 16px 16px 16px 0px;
    font-size: 16px;
    @include font-weight(500);
    border-bottom: 1px solid $grey3;
    list-style-type: none;
    &:last-child {
      border-bottom: none;
    }
  }
}

.searchItemList {
  position: relative;
  padding: 16px 0;
  &:not(:last-child) {
    border-bottom: 1px solid $grey3;
  }
  cursor: pointer;
  &.borderNone {
    border-bottom-color: transparent;
  }
  .title {
    color: $grey10;
    @include for-size(desktop) {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }
    @include for-size(phone) {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      margin-bottom: 4px;
    }
  }
  .subTitle {
    color: $grey8;
    font-weight: 400;
    @include for-size(desktop) {
      font-size: 14px;
      line-height: 20px;
    }
    @include for-size(phone) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.noResults {
  color: $red5;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 16px;
  text-align: left;
}

.icon-ic_location:before {
  content: "\eaa9";
  color: #02475b;
}

.viewAddressCta {
  justify-content: flex-start !important;
  padding: 0 0 16px !important;
  &:hover,
  &:focus {
    background: transparent !important;
    box-shadow: none !important;
  }
  @include for-size(phone) {
    font-size: 12px !important;
  }
}
.heading {
  color: $grey8;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px; /* 140% */
  letter-spacing: 1px;
  text-transform: uppercase;
  @include for-size(desktop) {
    margin-top: 16px;
  }
  @include for-size(phone) {
    margin: 16px 0 0;
  }
}

.highlight {
  background-color: $yellow2;
  font-weight: bold;
}
.separator {
  min-height: 2px;
  position: relative;
  width: 100%;
  @include flex(center, center, row);
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: $grey3;
    height: 1px;
    width: 100%;
  }
  .text {
    @include flex(center, center, row);
    background: $white;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 12px;
    color: $grey6;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    flex-shrink: 0;
    position: relative;
    z-index: 2;
  }
}
.separatorDashed {
  min-height: 2px;
  position: relative;
  width: 100%;
  @include flex(center, center, row);
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: 1px $grey3 dashed;
    height: 1px;
    width: 100%;
  }
}
.savedAdd {
  text-align: left;
  font-weight: 700;
  @include for-size(desktop) {
    font-size: 18px;
    line-height: 24px;
    margin: 12px 0;
  }
  @include for-size(phone) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
}
.iconBx {
  @include flex(center, flex-start, row);
  height: 20px;
  width: 20px;
  margin-left: 10px;
  flex-shrink: 0;
  position: relative;
  .icon {
    transform: rotate(-90deg);
    font-size: 10px;
    width: 7px;
    position: relative;
    top: 10px;
    position: absolute;
    left: 0;
    &::before {
      color: $pharma6;
    }
    &:last-child {
      left: 8px;
    }
  }
}
.savedAddressHeading {
  line-height: 24px;
  text-align: left;

  @include for-size(desktop) {
    margin: 12px 0;
    font-weight: 700;
    font-size: 18px;
  }
  @include for-size(phone) {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 600;
  }
}
.sentenceCase {
  text-transform: capitalize;
}
