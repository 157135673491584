@use "styles" as *;

.windowBody {
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 600px;
  overflow-y: auto;
  & p {
    font-size: 17px;
    font-weight: 500px;
    line-height: 1.41;
    color: $primaryColor;
    margin-top: 20px;
  }
  @media only screen and (max-width: 768px) {
    max-height: 360px;
  }
}
.successPopoverWindow {
  display: flex;
  margin-right: 5px;
  margin-bottom: 5px;
}
.windowWrap {
  width: 368px;
  border-radius: 10px;
  padding-top: 36px;
  background-color: $white;
  @media only screen and (max-width: 768px) {
    margin: 0 15px;
  }
}
.mascotIcon {
  position: absolute;
  right: 12px;
  top: -40px;
  & img {
    max-width: 80px;
  }
}
.actions {
  padding: 10px 20px 20px 20px;
  display: flex;
}
.button {
  background-color: transparent;
  box-shadow: none;
  margin-left: auto;
  font-weight: bold;
  color: #fc9916;
  padding: 0;
  &:hover {
    background-color: transparent;
  }
}
.saveAddressMain {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    inset: inherit !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 100%;
    max-width: 100%;
  }
}

.saveAddressMainTitle {
  padding: 16px 32px;
  text-align: left;
}

.saveAddressMainMidSection {
  border-radius: 0;
  background: $white;
  padding: 0 32px 4px 28px;
}

.cardListMain {
  display: flex;
  flex-wrap: wrap;
  max-height: calc(45vh - 20px);
  overflow: auto;
  padding-right: 4px;
  background: $white;
}

.dialogBox {
  > div {
    > div {
      @media only screen and (max-width: 767.9px) {
        max-width: 100%;
        width: 100%;
        border-radius: 0;
        margin: 10px 0 0;
        height: 100%;
        max-height: initial;
        background: $white;
        overflow-y: auto;
        position: relative;
        &:before {
          background: #fff;
          position: fixed;
          content: "";
          height: 50px;
          top: -20px;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}
.dialogBoxEditAddress {
  > div {
    > div {
      @media only screen and (max-width: 767.9px) {
        height: calc(100vh - 200px);
        position: fixed;
        bottom: 0;
        border-radius: 10px 10px 0 0;
        &:before {
          background: none;
        }
      }
    }
  }
}
.HeaderStrip {
  position: relative;
  > button {
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 15px;
  }
}
.outOfStockPopover {
  > div {
    > div {
      max-width: 100% !important;
      width: 500px;
      padding: 0 !important;
    }
  }
}
