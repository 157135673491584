@use "styles" as *;

.footerRoot {
  background: $pharma1;
  padding: 72px 0 112px;
  overflow-x: hidden;
  @include for-size(desktop) {
    margin-top: 80px;
  }
  @include for-size(phone) {
    padding: 40px 0 158px;
  }
  .accordionContainer {
    @include flex(flex-start, space-between, row);
    padding-bottom: 32px;
    border-bottom: 1px solid $pharma3;
    @include for-size(phone) {
      flex-direction: column;
    }
  }

  .accordion {
    flex: 1 0 auto;
    .accordionHeader {
      @include flex(center, space-between, row);
    }
    @include for-size(phone) {
      width: 100%;
      border-color: $white;
      &:last-child {
        border: none;
      }
    }
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    min-height: 40px;
  }

  .footerLinks {
    li {
      > a {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin-bottom: 16px;
      }
    }
    @include for-size(phone) {
      padding: 0 0 20px;
    }
  }
  .footerLogo {
    width: 132px;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    @include for-size(phone) {
      margin-bottom: 50px;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  .footerLinksWrap {
    margin-top: 40px;
    @include flex(stretch, space-between, row);
    @include for-size(phone) {
      flex-direction: column;
      align-items: center;
      .title {
        text-align: center;
      }
    }
  }

  .socialLink {
    min-height: 58px;
    @include flex(center, space-between, row);
    flex-wrap: wrap;
    li {
      margin-right: 18px;
      flex-shrink: 0;
      a {
        @include flex(center, center, row);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 6px;
        background-color: $grey10;
        > span {
          &::before {
            color: $pharma1;
          }
        }
      }
      &.youtubeIcon {
        > a {
          background: transparent;
          font-size: 40px;
          > span {
            font-size: 40px;
            > span {
              &:first-child {
                &::before {
                  color: $pharma1;
                }
              }
              &:last-child {
                &::before {
                  color: $grey10;
                }
              }
            }
          }
        }
      }
    }
  }

  .appLinks {
    @include flex(center, space-between, row);
    li {
      padding: 0 5px;
      > a {
        span {
          font-size: 54px;
          @include for-size(phone) {
            font-size: 40px;
          }
        }
      }
    }
  }
  .downloadAppContainer {
    text-align: center;
    @include for-size(phone) {
      margin-bottom: 50px;
    }
    @include for-size(phone) {
      order: 1;
    }
  }
  .socialLinkContainer {
    @include for-size(phone) {
      padding: 20px 0;
      order: 2;
    }
  }

  .expandIcon {
    @include for-size(phone) {
      width: 12px;
      height: 12px;
      border-bottom: 2px solid $grey10;
      position: relative;
      top: -12px;
      &:before {
        content: "";
        position: absolute;
        height: 12px;
        border-left: 2px solid $grey10;
        top: 5px;
        bottom: 0;
        left: 5px;
      }
    }
    &.active {
      &::before {
        height: 0;
        top: 0;
      }
    }
  }
  .hide {
    @include for-size(phone) {
      opacity: 0;
      height: 0;
      pointer-events: none;
      overflow: hidden;
    }
  }
}
