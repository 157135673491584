@use "styles" as *;

.root {
  @include flex(flex-start, space-between, column);
  .header {
    min-height: 150px;
    width: 100%;
    @include flex(center, center, row);
    background: #f69521;
    width: 100%;
  }
  .formRoot {
    width: 100%;
  }
  .middleSection {
    padding: 16px 16px 8px;
    height: 240px;
    overflow-x: hidden;
    width: 100%;
    .titleBx {
      padding-top: 20px;
      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 16px;
        color: $aquaBlue;
      }
      .editNumber {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        @include flex(center, flex-start, row);
        margin-bottom: 6px;
        min-height: 24px;
        .icon {
          height: 24px;
          width: 24px;
          @include flex(center, center, row);
          flex-shrink: 0;
          margin-left: 8px;
        }
      }

      &.whenShowBackArrow {
        padding: 0;
        margin-bottom: 8px;
        .title {
          margin-bottom: 8px;
        }
      }
    }
  }

  .footer {
    min-height: 74px;
    width: 100%;
    @include flex(center, center, row);
    flex-wrap: wrap;
    .footerBnr {
      padding: 16px;
      height: auto;
      > img {
        height: auto;
      }
    }
    .offerStrip {
      min-height: 30px;
      background: $orange10;
      text-align: center;
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $orange2;
      width: 100%;
      flex-shrink: 0;
      @include flex(center, center, row);
    }
  }
  .phoneInputBx {
    @include flex(center, space-between, row);
    margin-bottom: 8px;
    position: relative;
    &.otpError {
      border-color: $red5;
    }
  }
  .btn {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    flex-shrink: 0;
    min-width: initial;
    @include flex(center, center, row);
    .icon {
      font-size: 40px;
    }
  }

  .phoneInput {
    border-bottom: 2px solid $pharma6;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    width: calc(100% - 72px);
    @include flex(center, flex-start, row);
    .input {
      border: none;
      width: calc(100% - 40px);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding-left: 10px;
      background: $white;
      &:focus-visible {
        outline: none;
        background: transparent;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        background-color: $white !important;
      }
    }
  }
  .otpContainer {
    height: 52px;
    @include flex(center, flex-start, row);
    width: calc(100% - 72px);
    position: relative;
    > input {
      padding: 8px 0;
      border-bottom: solid $pharma6;
      border-width: 0 0 2px 0;
      text-align: center;
      width: 34px;
      border-radius: 2px;
      caret-color: $pharma6;
      height: 52px;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      &:focus-visible {
        outline: none;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    &.inputError {
      > input {
        border-color: $red5;
      }
    }
  }
  .extraInfo {
    min-height: 68px;
    .subText {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $grey8;
      min-height: 16px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .otpExtraInfo {
    @include flex(center, flex-start, row);
    flex-wrap: wrap;
    transition: all 0.2s ease-in-out;
    min-height: 24px;
    .subText {
      margin-bottom: 0 !important;
    }
  }
}
.subText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $grey8;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.titleClass {
  background: #f69521;
  padding: 4px !important;
  border-radius: 10px 10px 0 0;
}
.titleClass span {
  height: 20px;
  width: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  &::before,
  &::after {
    position: absolute;
    left: 8px;
    top: 4px;
    content: "";
    height: 22px;
    width: 2px;
    border-radius: 4px;
    background-color: #121414;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.circleModalContent {
  padding: 0 !important;
  border-width: 0 !important;
  @include for-size(desktop) {
    border-radius: 0 0 10px 10px;
  }
}

.icon-edit_pencil:before {
  content: "\ebb7";
  color: #165d59;
}

.helpText {
  font-size: 12px;
  @include font-weight(500);
  color: rgba($primaryColor, 0.6);
  margin-top: -10px;
  line-height: 20px;
}
.timer {
  font-size: 12px !important;
  margin-left: 6px;
}

.enterPhoneScreen {
  animation: slideIn 0.2s ease-in forwards;
}
.otpRoot {
  animation: slideIn 0.2s ease-out forwards;
  .backArrow {
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    display: block;
    animation: slideIn 0.3s ease-out forwards;
  }
}
@keyframes slideIn {
  0% {
    @include transform(translateX(10%));
    opacity: 0;
  }
  100% {
    @include transform(translateX(0));
    opacity: 1;
  }
}
.modalboxClass {
  max-height: 100dvh !important;
}
.errorMsg {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $red5 !important;

  &.wrongPhoneNum {
    position: absolute;
    left: 0;
    bottom: -4px;
  }
}
////
.oppsTitle {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  color: $aquaBlue !important;
}
.oppsSubtitle {
  font-size: 18px !important;
  line-height: 24px;
  margin-bottom: 16px;
  color: $grey9 !important;
}
.tryagain {
  padding: 16px 0;
  .ootimer {
    font-weight: 400;
    padding: 0 6px;
    color: $grey8 !important;
    font-size: 12px !important;
  }
}
