@use "styles" as *;
.iconBtn {
  @include flex(center, flex-start, row);
  padding: 2px 0;
  border: none;
  background: transparent;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid $grey4;
    padding-right: 8px;
    margin-right: 8px;
  }
  &.disabled {
    color: $pharma2;
  }
  .icon {
    position: relative;
    font-size: 16px;
    &::before {
      color: $pharma6;
    }
  }
}
.btnContainer {
  padding: 40px 0 0;
  @include flex(center, space-between, row);
  gap: 8px;
  button {
    width: 150px;
  }
}
