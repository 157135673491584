@font-face {
  font-family: "icomoon";
  src: url("../../src/components/icomoon-restOfThePage/fonts/icomoon.eot?3t2je5");
  src: url("../../src/components/icomoon-restOfThePage/fonts/icomoon.eot?3t2je5#iefix")
      format("embedded-opentype"),
    url("../../src/components/icomoon-restOfThePage/fonts/icomoon.ttf?3t2je5")
      format("truetype"),
    url("../../src/components/icomoon-restOfThePage/fonts/icomoon.woff?3t2je5")
      format("woff"),
    url("../../src/components/icomoon-restOfThePage/fonts/icomoon.svg?3t2je5#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*="icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-logoff:before {
  content: "\ebc0";
}
.icon-rxprescription:before {
  content: "\ebc6";
  color: #000;
}
.icon-pplbought:before {
  content: "\ebc4";
}
.icon-returnback:before {
  content: "\ebc5";
}
.icon-hiparsort:before {
  content: "\ebc0";
}
.icon-hiparwarning:before {
  content: "\ebc1";
  color: #da2f31;
}
.icon-ibtn:before {
  content: "\ebc2";
  color: #106c89;
}
.icon-twitter-7:before {
  content: "\ebd1";
}
.icon-Express-Delivery:before {
  content: "\eba4";
  color: #71716e;
}
.icon-Share:before {
  content: "\eba5";
}
.icon-Vector:before {
  content: "\eba6";
  color: #bebebb;
}
.icon-cart_icon:before {
  content: "\eba1";
  font-size: 30px;
}
.icon-searchIcon:before {
  content: "\eb95";
}
.icon-ic_loginback:before {
  content: "\eafc";
  color: #02475b;
}
.icon-ic_cross:before {
  content: "\e96f";
  color: #02475b;
}
.icon-userIcon:before {
  content: "\eb92";
  color: #fff;
}

.icon-order:before {
  content: "\eb9e";
}
.icon-help_icon:before {
  content: "\eba0";
}
.icon-manage_family:before {
  content: "\eb98";
}
.icon-memberships:before {
  content: "\eb9a";
}
.icon-payments_ico:before {
  content: "\eb9b";
}
.icon-health_records:before {
  content: "\eb9c";
}
.icon-logout:before {
  content: "\eb9d";
}
.icon-ic-down_arrow:before {
  content: "\eb87";
  color: #121414;
}
.icon-address_icon:before {
  content: "\eb9f";
}
.icon-ic_arrow_right:before {
  content: "\ea92";
  color: #02475b;
}
.icon-ic_fees::before {
  content: "\ea9b";
  color: #02475b;
}
.icon-cod_icon:before {
  content: "\eba7";
  color: #71716e;
  font-size: 12px;
}
.icon-ic_gps1:before {
  content: "\eb4f";
  color: #fff;
}
.icon-ic_arrow_forward:before {
  content: "\eaeb";
  color: #fff;
}
.icon-ic_home:before {
  content: "\eaa7";
  color: #02475b;
}
.icon-office:before {
  content: "\eb6c";
  color: #01475b;
}
.icon-ic_location:before {
  content: "\eaa9";
  color: #02475b;
}
.icon-manage_family:before {
  content: "\eb98";
}
.icon-ic_delete:before {
  content: "\e970";
  color: #03475a;
}
.icon-edit_pencil:before {
  content: "\ebb7";
  color: #165d59;
}
.icon-ic-down_arrow-location:before {
  content: "\eb87";
  color: #165d59;
}
