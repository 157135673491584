@use "styles" as *;

.pnfContainer {
  .pnfContent {
    background: #f9f9f9;
    padding: 30px;
  }
  .pnfTopContent {
    @include flex(center, unset, row);
    padding: 30px 0;
    margin-top: 60px;
    @media screen and (max-width: 767.9px) {
      flex-direction: column;
    }
    & > img {
      flex: 1 0 auto;
      text-align: center;
      position: relative;
      top: 40px;
      max-width: 500px;
      @media screen and (max-width: 767.9px) {
        max-width: 200px;
        top: 0;
      }
    }
  }
  .pnfDetails {
    flex: 1 0 auto;
    @media screen and (max-width: 767.9px) {
      text-align: center;
    }
    > img {
      margin: 0 0 10px;
      @media screen and (max-width: 767.9px) {
        width: 250px;
      }
    }
    > h2 {
      font-size: 28px;
      line-height: 40px;
      @include font-weight(700);
    }
    > p {
      font-size: 16px;
      line-height: 28px;
      @include font-weight(400);
      color: #c4c4c4;
      letter-spacing: 4px;
    }
  }
  .goBackHome {
    cursor: pointer;
    font-size: 20px;
    line-height: 28px;
    @include font-weight(400);
    letter-spacing: 3px;
    color: $secondaryColor;
    text-transform: uppercase;
    margin: 20px 0 0;
    @include flex(center, unset, row);
    & img {
      margin: 0 0 0 10px;
      display: inline-block;
    }
    > span {
      height: 28px;
      &::before {
        font-size: 16px;
        margin-left: 10px;
      }
    }
    @media screen and (max-width: 767.9px) {
      display: block;
    }
  }
  .usefulLinks {
    padding: 30px 30px 0;
    @media screen and (max-width: 767.9px) {
      padding: 30px 0 0;
    }
    > h3 {
      width: 70%;
      margin: 0 auto;
      text-align: center;
      position: relative;
      @media screen and (max-width: 767.9px) {
        width: 100%;
      }
      &:before {
        content: "";
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        border-top: 1px solid rgba($black, 0.1);
        z-index: 0;
      }
      & span {
        font-size: 18px;
        @include font-weight(500);
        line-height: 26px;
        color: #979797;
        padding: 0 20px;
        background: #f9f9f9;
        text-transform: uppercase;
        position: relative;
        z-index: 2;
      }
    }
  }
  .usefulLinksList {
    margin: 0 0 20px;
    padding: 0;
    @include flex(flex-start, unset, row);
    flex-wrap: wrap;
    > li {
      margin: 0 40px 10px 0;
      list-style-position: inside;
      > a {
        padding: 0;
        font-size: 16px;
        @include font-weight(400);
        line-height: 26px;
      }
      @media screen and (max-width: 767.9px) {
        margin: 0 0 10px 0;
        > a {
          font-size: 14px;
          margin-right: 20px;
        }
      }
    }
  }
  .gridContainer {
    padding: 30px 0 0;
    @include flex(flex-start, space-between, row);
    @media screen and (max-width: 767.9px) {
      flex-direction: column;
    }
    > h4 {
      font-size: 20px;
      @include font-weight(700);
      line-height: 26px;
      margin: 0 0 20px;
      text-transform: uppercase;
    }
  }
  .footerLinks {
    @media screen and (max-width: 991.9px) {
      display: none;
    }
  }
  .ulContent {
    width: 100%;
    padding: 0 0 10px;
    > h4 {
      margin: 0 0 20px;
      font-size: 20px;
      @include font-weight(700);
      line-height: 26px;
      text-transform: uppercase;
    }
  }
}
.icon-arrow-orange:before {
  content: "\e910";
  color: #fc9916;
}
