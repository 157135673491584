@use "styles" as *;

.container {
  min-height: 80px;
  padding: 16px;
  color: $white;
  text-align: left;
  padding: 16px;
  gap: 16px;
  @include border-radius(14px);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  .subHeading {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.bgGreen {
  background-color: $green6;
  @include flex(center, flex-start, row);
  .icon {
    height: 48px;
    width: 48px;
    @include border-radius(50%);
    flex-shrink: 0;
    @include flex(center, center, row);
    background: $green8;
    position: relative;
    &::before {
      font-size: 10px;
    }
    &::after {
      content: "";
      height: 18px;
      width: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include border-radius(50%);
      border: 1px solid $white;
    }
  }
}

.bgRed {
  background-color: $red5;
  @include flex(center, flex-start, row);
  .icon {
    height: 48px;
    width: 48px;
    @include border-radius(50%);
    flex-shrink: 0;
    background: $red9;
    @include flex(center, center, row);
    &::before {
      position: relative;
      top: -2px;
    }
  }
}
.infoBx {
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    color: $white;
  }
  .subTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $white;
  }
}

.heading {
  color: $grey10;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
  text-align: left;
}

.subheading {
  color: $grey8;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
  text-align: left;
}
.icon-tick1:before {
  content: "\eb80";
}
.icon-stop:before {
  content: "\eb6f";
  color: $white;
}
