@use "../abstract/variables" as *;
@use "../abstract/mixins" as *;

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  min-height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  padding: 0 !important;
}

body {
  font-size: 14px;
  background: $white;
  color: $grey10;
  width: 100%;
  font-weight: 400;
}
body,
button,
input {
  font-family: $font;
}
a {
  text-decoration: none;
  color: inherit;
  outline: 0;
  display: inline-block;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  margin: 0;
  font-weight: normal;
}
#__next,
#next {
  min-height: 100%;
  position: relative;
  z-index: 2;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
}
.pageLoader {
  position: absolute;
  top: 0;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.icon-whatsapp:before {
  content: "\eba2";
  color: #fff;
}
.icon-ic_cart:before {
  content: "\ea95";
  color: #02475b;
}
.icon-ic_account:before {
  content: "\eae7";
  color: #02475b;
}
.icon-ic_doctors:before {
  content: "\eade";
  color: #02475b;
}
.icon-Linkedin:before {
  content: "\eb5d";
  color: #0066b7;
}
.icon-twitter-6:before {
  content: "\eb62";
  color: #1da1f2;
}
.icon-youtube-icon .path1:before {
  content: "\eb63";
  color: rgb(255, 255, 255);
}
.icon-youtube-icon .path2:before {
  content: "\eb64";
  margin-left: -1em;
  color: rgb(232, 224, 224);
}
.icon-youtube-icon .path3:before {
  content: "\eb65";
  margin-left: -1em;
  color: rgb(205, 32, 31);
}
.icon-ic-search:before {
  content: "\ea26";
  color: #00b38e;
}
.icon-offer-icon:before {
  content: "\ea5a";
  color: #01475b;
}
.icon-ic_account_white:before {
  content: "\ea93";
  color: #fff;
}
.icon-Facebook:before {
  content: "\eb50";
  color: #0075fa;
}

.icon-ios-app-btn .path1:before {
  content: "\eb51";
  color: rgb(228, 228, 228);
}
.icon-ios-app-btn .path2:before {
  content: "\eb52";
  margin-left: -3.37109375em;
  color: rgb(4, 4, 4);
}
.icon-ios-app-btn .path3:before {
  content: "\eb53";
  margin-left: -3.37109375em;
  color: rgb(92, 92, 92);
}
.icon-ios-app-btn .path4:before {
  content: "\eb54";
  margin-left: -3.37109375em;
  color: rgb(124, 124, 124);
}
.icon-ios-app-btn .path5:before {
  content: "\eb55";
  margin-left: -3.37109375em;
  color: rgb(132, 132, 132);
}
.icon-ic_bell_notify:before {
  content: "\eb7a";
  color: #fc9916;
}

.icon-circle .path1:before {
  content: "\ea76";
  color: none;
}
.icon-circle .path2:before {
  content: "\ea77";
  margin-left: -1.58984375em;
  color: rgb(248, 149, 33);
}
.icon-circle .path3:before {
  content: "\ea78";
  margin-left: -1.58984375em;
  color: rgb(1, 68, 89);
}
.icon-cross_svg .path1:before {
  content: "\eb56";
  color: rgb(252, 183, 22);
}
.icon-cross_svg .path2:before {
  content: "\eb57";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-graph_up:before {
  content: "\eb7b";
  color: $green6;
}
.icon-ic_history:before {
  content: "\eb84";
  color: $purple5;
}
.icon-phone-ico:before {
  content: "\ea5d";
  color: #00485d;
}
.icon-timerIcon:before {
  content: "\ebb3";
  color: #71716e;
}

[class="icon-ic_location"] {
  margin: 0 10px 0 0;
}
[class="icon-manage_family"] {
  margin: 0 10px 0 0;
}

[class="icon-ios-app-btn"] {
  font-size: 36px;
}
[class="icon-google-play-btn"] {
  font-size: 36px;
}

[class="icon-circle"] {
  font-size: 20px;
  margin-right: 3px;
}

.wzrk-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.wzrk-alert {
  top: unset !important;
  bottom: 20px;
  background-image: linear-gradient(#1185a0, #1185a0, #34c1d6) !important;
  padding-bottom: 20px !important;
  width: 380px !important;
  border: none !important;
}

.wzrk-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.wzrk-alert {
  top: unset !important;
  bottom: 20px;
  background-image: linear-gradient(#1185a0, #1185a0, #34c1d6) !important;
  padding-bottom: 20px !important;
  width: 380px !important;
  border: none !important;
}

.wzrk-button-container button {
  border-radius: 16px !important;
}

.wzrk-button-container #wzrk-confirm:hover {
  background-image: linear-gradient(to right, #f25807, #ffb41d);
}

.wzrk-button-container #wzrk-confirm {
  background: linear-gradient(to right, #ffb41d, #f25807);
  font-weight: 700 !important;
}

.wzrk-button-container #wzrk-cancel {
  background: rgb(255 255 255 / 70%);
  color: #014459 !important;
  font-weight: 700 !important;
}

.wzrk-button-container #wzrk-cancel:hover {
  background: #fff !important;
}
.wzrk-alert-body {
  color: #fff !important;
  margin-bottom: 20px !important;
}
.wzrk-alert-body h3 {
  font-weight: 700 !important;
  margin-bottom: 5px;
}

.wzrk-powered {
  display: none;
}

@media screen and (max-width: 767px) {
  .wzrk-alert {
    bottom: 100px;
    width: auto !important;
  }
  .wzrk-button-container #wzrk-cancel {
    background: #fff;
  }
}
//remove button code after implement from ui-lib
button {
  &:focus-visible {
    outline: none;
  }
}

// jio chat global code
jdiv[class*="label"][class*="bottom"] {
  bottom: 80px !important;
  height: 62px;
  min-width: unset !important;
  width: 174px;
  right: 90px !important;
  border-radius: 0 !important;
  opacity: 0 !important;
}
jdiv[class*="label"][class*="bottom"]
  > [class*="text"][class*="contentTransitionWrap"] {
  font-size: 0 !important;
}
@media screen and (max-width: 767px) {
  jdiv[class*="orientationRight"][class*="wrap"] {
    bottom: 120px !important;
    right: 72px !important;
    width: 105px !important;
    height: 46px !important;
    overflow: hidden !important;
  }
  jdiv[class*="orientationRight"][class*="wrap"] > [class*="button"] {
    margin: 0 !important;
    width: 105px;
    opacity: 0 !important;
  }
}

.icon-google-play-btn .path1:before {
  content: "\eb5f";
  color: rgb(220, 219, 207);
}
.icon-google-play-btn .path2:before {
  content: "\eb60";
  margin-left: -3.3896484375em;
  color: rgb(4, 217, 216);
}
.icon-google-play-btn .path3:before {
  content: "\eb61";
  margin-left: -3.3896484375em;
  color: rgb(4, 4, 4);
}
.icon-google-play-btn .path4:before {
  content: "\eb66";
  margin-left: -3.3896484375em;
  color: rgb(239, 47, 72);
}
.icon-google-play-btn .path5:before {
  content: "\eb67";
  margin-left: -3.3896484375em;
  color: rgb(94, 91, 89);
}

/// New Design
// icons styles start ///
.icon-healthRecord:before {
  content: "\eb8a";
  color: #228a87;
}
.icon-labTest:before {
  content: "\eb8b";
  color: #228a87;
}
.icon-medicineIco:before {
  content: "\eb8c";
  color: #228a87;
}
.icon-consult:before {
  content: "\eb8d";
  color: #228a87;
}
.icon-mobileHome:before {
  content: "\eb8e";
  color: #228a87;
}
.icon-Home:before {
  content: "\eb8e";
}
.icon-offers:before {
  content: "\eb8f";
}
.icon-bell:before {
  content: "\eb90";
  color: #fff;
}
.icon-hcWallet:before {
  content: "\eb91";
  color: #fff;
}
.icon-user:before {
  content: "\eb92";
  color: #fafbf9;
}
.icon-cart:before {
  content: "\eb93";
  color: #228a87;
}
.icon-icon_ellipse:before {
  content: "\ebc9";
  color: #1a504c;
}
.icon-my-insurance:before {
  content: "\ebc3";
  color: #228a87;
  -webkit-text-stroke-width: 0.3px;
  font-size: 26px;
}
// icons styles end ///

.revampedContainer,
.container {
  width: 100%;
  max-width: 1276px;
  margin: 0 auto;
  padding: 0 16px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  @include for-size(phone) {
    margin: 0 -10px;
  }
}
[class*="col-"] {
  padding: 0 10px;
  @include for-size(phone) {
    padding: 0 6px;
  }
}

.sectionTop {
  @include flex(flex-start, space-between, row);
  user-select: none;
}

.sectionHeading {
  @include for-size(phone) {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 16px;
  }
  @include for-size(desktop) {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    margin-bottom: 24px;
  }
}
// grid system
@each $breakpoint, $value in $grid-breakpoints {
  @media (min-width: $value) {
    @for $i from 1 through $grid-columns {
      .col-#{$breakpoint}-#{$i} {
        flex-basis: 100% / $grid-columns * $i;
        max-width: 100% / $grid-columns * $i;
      }
    }
  }
}
.handleCircle {
  [alt~="circle"] {
    width: 100%;
    height: 130px;
  }
}
// common sticky css
.sticky {
  position: sticky;
  top: 0;
  z-index: 9;
  @include for-size(phone) {
    transition: all 0.5s ease-in-out;
    z-index: 8;
    &.showHeader {
      top: -60px;
    }
  }
}
.appHeader {
  position: sticky;
  top: 0;
  width: 100%;
  background: $white;
  z-index: 8;
  height: 56px;
  padding: 0 16px;
  border-bottom: 1px solid $grey3;
}
//common search modal scss
.searchRootMain {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  @include for-size(desktop) {
    z-index: 8;
    top: 61px;
    padding: 56px 30px 0;
    //animation: searchOpen 1s;
    @keyframes searchOpen {
      0% {
        @include transform(scale(0));
      }
      100% {
        @include transform(scale(1));
      }
    }
  }
  @include for-size(phone) {
    z-index: 9;
    height: 100dvh;
    top: 0;
  }

  .searchContainer {
    margin: 0 auto;
    width: 100%;
    position: relative;
    max-width: 932px;
    @include for-size(desktop) {
      @include flex(flex-start, space-between, row);
      height: 100%;
    }
    .cartIcons {
      @include for-size(phone) {
        position: fixed;
        right: 16px;
        top: 8px;
        height: 48px;
        width: 48px;
        @include flex(center, center, row);
        cursor: pointer;
        z-index: 2;
        font-size: 20px;
        .cartIcon {
          &::before {
            color: $brandColor7;
          }
        }
        .notify {
          position: absolute;
          top: 2px;
          right: 2px;
          height: 20px;
          width: 20px;
          @include border-radius(50%);
          background: $pharma9;
          color: $white;
          font-size: 10px;
          font-weight: 700;
          line-height: 20px;
          @include text-overflow(ellipsis);
          display: inline-block;
          text-align: center;
        }
      }
    }

    .closeIcon {
      height: 48px;
      width: 48px;
      @include flex(center, center, row);
      cursor: pointer;
      font-size: 24px;
      &::before {
        color: $brandColor7;
      }
    }
    .searchBx {
      @include for-size(desktop) {
        max-width: calc(932px - 88px);
        width: 100%;
      }
      @include for-size(phone) {
        height: 100dvh;
        overflow: hidden;
        position: relative;
      }
    }
  }
}

.icon-ic_cross:before {
  content: "\e96f";
  color: #02475b;
}
.icon-searchIcon:before {
  content: "\eb95";
}
.searchRoot {
  @include for-size(desktop) {
    overflow: hidden;
    width: 100%;
    .insideSearchContainer {
      padding: 2px 9px 0 0;
      margin: 0 auto;
      text-align: center;
    }

    .searchSelectMain {
      > div {
        height: 56px;
        > span {
          height: 24px;
          width: 24px;
          font-size: 24px;
          &::before {
            color: $pharma6;
          }
        }
        > input {
          &::placeholder {
            color: $grey9;
            font-size: 16px;
            font-weight: 400;
            opacity: 0.7;
          }
        }
      }
    }

    .verticalSepration {
      width: 1px;
      background: #d8f2e6;
      height: 100%;
      display: inline-block;
      margin-left: 50%;
    }
    .uploadPresBx {
      button {
        justify-content: center;
      }
    }
  }
}
.errorWrap {
  @include flex(center, center, row);
  height: calc(100vh - 140px);
}

.clevertapCloseBtn {
  position: fixed;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  z-index: 1000;
  display: none;
}
@media screen and (max-width: 767px) {
  .clevertapCloseBtn {
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    top: 0;
  }
}
.mdContainer {
  padding: 20px;
}
.thumbnailforCarosuel .keen-slider__slide.active {
  border-color: #156f6c;
}
.zeroMargin {
  margin: 0 !important;
}
.stickyAppDownloadBanner {
  height: 60px;
  overflow: hidden;
  img {
    @include border-radius(0 !important);
  }
}
// customicon
.arrow {
  height: 7px;
  width: 16px;
  @include flex(center, center, row);
  margin-left: 4px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  &::before,
  &::after {
    content: "";
    background: $white;
    width: 2px;
    @include border-radius(5px);
    height: 100%;
    position: absolute;
    top: calc(50% - 4px);
  }
  &::before {
    @include transform(rotate(45deg));
    right: 30%;
  }
  &::after {
    @include transform(rotate(-45deg));
    left: 30%;
  }
}
.icon-expDel:before {
  content: "\e777";
  color: #187232;
  font-size: 12px;
  position: relative;
  top: -1px;
}
.icon-language:before {
  content: "\e789";
  cursor: pointer;
  user-select: none;
}
// whatsapp common code
.whatsUpBannerMain {
  position: relative;
  background: linear-gradient(
    92.82deg,
    #fff6de 2.08%,
    #ffddd6 74.9%,
    #fff4dc 97.17%
  );
  border-radius: 5px;
  @include flex(center, space-between, row);
  overflow: hidden;
  @include for-size(desktop) {
    height: 157px;
    padding: 0 30px;
  }
  @include for-size(phone) {
    padding: 10px 14px 0;
    margin-bottom: 20px;
    height: 137px;
  }
}
.logoBx {
  @include for-size(phone) {
    position: absolute;
    right: 10px;
    top: 5px;
    width: 60px;
    height: 37px;
  }
}

.icon-hamberger {
  height: 14px !important;
  width: 22px;
  border: solid $grey10;
  border-width: 2px 0 2px 0;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    @include border-radius(2px);
    background: $grey10;
  }
  &::before {
    top: 50%;
    transform: translateY(-50%);
  }
}
