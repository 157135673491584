@use "styles" as *;

.locationContainer {
  position: relative;
  height: 36px;
  width: 100%;
  cursor: pointer;
  @include flex(center, flex-start, row);
  @include for-size(phone) {
    width: auto;
  }

  &:before {
    border-color: $white;
  }
  &.miniAppUser {
    padding: 10px;
    margin: 0 0 0 10px;
  }
  &.miniAppUser {
    padding: 10px;
    margin: 0 0 0 10px;
  }
  .locationIcon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
    flex-shrink: 0;
    cursor: pointer;
    color: $black;
    -webkit-text-stroke: 0.3px $black;
    > img {
      @include imageResponsive;
    }
  }
}
.location {
  position: relative;
  cursor: pointer;
  @include for-size(desktop) {
    width: calc(100% - 32px);
  }
  @include for-size(phone) {
    border-bottom: 0;
    padding: 0 20px 0 0;
  }
  &:after {
    content: "";
    position: absolute;
  }
  &.active {
    .arrowIcon {
      @include transform(rotate(180deg));
    }
  }
}
.locationPay {
  padding: 0 20px 2px 0;
  position: relative;
  cursor: pointer;
}

.locationContent {
  background: $white;
  @include border-radius(16px);
  position: absolute;
  left: -28px;
  top: 48px;
  padding: 16px;
  width: 290px;
  z-index: 9;
  @include box-shadow(0 0 5px rgba(190, 190, 187, 0.6));
  @include for-size(phone) {
    left: -4px;
  }
}
.currentLocation {
  margin: 8px 0;
  cursor: pointer;
  border: 1px solid $pharma6;
  height: 40px;
  @include border-radius(8px);
  @include flex(center, center, row);
  background: $pharma6;
  > p {
    color: $white;
    font-size: 16px;
  }
  > span {
    font-size: 16px;
    margin-right: 10px;
    flex-shrink: 0;
  }
}
.pincodeOption {
  @include flex(center, unset, row);
  padding: 5px 0;
  min-height: 28px;
  padding: 0;
  cursor: pointer;
  > .icon {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    flex-shrink: 0;
    color: $pharma6;
    -webkit-text-stroke: 0.6px $pharma6;
    font-size: 20px;
  }
}
.locationText {
  color: $pharma6;
  font-weight: 600;
  font-size: 14px;
}
.divider {
  position: relative;
  @include flex(center, center, row);
  height: 30px;
  &:before {
    content: "  ";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    border-top: 1px solid $grey3;
  }
  > p {
    padding: 0 15px;
    font-size: 14px;
    display: inline-block;
    background: $white;
    position: relative;
    color: $grey8;
  }
}
.pincodeData {
  padding: 0 4px;
  font-size: 14px;
  color: $primaryColor;
  @include font-weight(500);
}
.pinModalTitle {
  @include box-shadow(none);
  align-items: flex-start;
  h2 {
    font-size: 32px;
    line-height: 42px;
    text-align: left;
    @include font-weight(700);
  }
}
.bottomActions {
  text-align: center;
  padding-top: 16px;
}
.submitBtn {
  background: $secondaryColor !important;
  color: $white !important;
  width: 150px;
  border-radius: 10px;
  padding: 16x;
  font-size: 13px;
  @include font-weight(700);
  &:hover {
    background: $secondaryColor;
    color: $white;
  }
}
.disabledBtn {
  opacity: 0.6;
  cursor: not-allowed !important;
}
.pincodeError {
  font-size: 13px;
  color: $red5;
  padding-top: 8px;
  @include font-weight(500);
}
.noService {
  color: $red5 !important;
}
.serviceable {
  color: $pharma6 !important;
}
.locationRoot {
  display: flex;
  .arrowIcon {
    font-size: 9px;
    height: 20px;
    width: 20px;
    @include flex(center, center, row);
    @include transition(all 0.2s ease-in-out);
    flex-shrink: 0;
    @include for-size(phone) {
      font-size: 6px;
      &::before {
        color: $grey1;
      }
    }
  }
}
.selectState {
  font-weight: 600;
  color: $black;
}
.textEllipses {
  @include text-overflow(ellipsis);
  line-height: 20px;
  color: $grey10;
  max-width: 150px;
  @include for-size(phone) {
    color: $grey2 !important;
    font-size: 12px;
  }
}

.displayInlineBlk {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: #4b4a48;
  @include line-clamp(1);
  @include for-size(desktop) {
    font-weight: 400;
  }
  @include for-size(phone) {
    font-weight: 600;
    color: #f1f1ef;
    margin-bottom: 2px;
  }
}
.disableLocation {
  opacity: 0.5;
  max-width: fit-content;
  pointer-events: none;
  user-select: none;
}

.disableLocationUpdate {
  cursor: not-allowed;
  .currentLocation,
  .pincodeOption {
    opacity: 0.5;
    filter: grayscale(1);
    pointer-events: none;
  }
}
.pincodeWrap {
  padding-top: 0;
}
.icon-ic_location:before {
  content: "\eaa9";
  color: $primaryColor;
}

.greyBackground {
  background-color: rgba($black, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
}
.icon-ic-down_arrow-location:before {
  content: "\eb87";
  color: $pharma6;
}
.toastMessage {
  > div {
    top: 62px !important;
  }
}
