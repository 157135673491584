@use "styles" as *;

.signUpBar {
  padding: 10px 0;
  display: flex;
  @media screen and (max-width: 767.9px) {
    text-align: center;
  }
}
.signUpPop {
  width: 368px;
  @include border-radius(10px);
  background: $white;
  @media screen and (max-width: 767.9px) {
    width: 100%;
  }
}

.mascotIcon {
  position: absolute;
  right: 12px;
  top: -40px;
  > img {
    max-width: 80px;
  }
}
.signinGroup {
  padding: 0 20px;
  .title {
    color: $primaryColor;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
  }
  .txt {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.41;
    color: $aquaBlue;
    margin-top: 20px;
  }
}
.actions {
  padding: 0 20px;
  display: flex;
  .button {
    @include box-shadow(none);
    margin-left: auto;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
}
