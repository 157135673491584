@use "styles" as *;
.modalContentClass {
  padding-top: 0;
  text-align: center;
  @include for-size(desktop) {
    border: none;
  }
  @include for-size(phone) {
    padding: 16px !important;
  }
}

.AddressModalboxClass {
  @include for-size(desktop) {
    max-height: none;
    height: 100dvh;
    @include border-radius(12px 0 0 12px);
    width: 400px;
  }
}

.modalTitle {
  @include for-size(desktop) {
    gap: 16px;
  }
  @include for-size(phone) {
    gap: 8px;
    > span {
      height: 20px;
    }
  }
  h2 {
    font-size: 18px !important;
    font-weight: 600 !important;
    @include for-size(desktop) {
      line-height: 24px;
    }
    @include for-size(phone) {
      line-height: 20px;
    }
  }
  &.defaultTitle {
    @include for-size(phone) {
      padding: 24px 16px 8px !important;
      position: sticky;
      top: 0;
      z-index: 2;
      background: $white;
      width: 100%;
      @include border-radius(10px 10px 0 0);
      h2 {
        line-height: 20px !important;
      }
    }
  }
  &.mobileHeader {
    @include for-size(phone) {
      height: 56px;
      padding: 6px 16px;
      h2 {
        font-size: 12px !important;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
  &.searchMedicineModalTitle {
    background: $white;
    padding: 20px 16px;
    height: 56px;
  }
  &.cardModalTitle {
    @include for-size(phone) {
      padding: 26px 16px 8px;
      height: 56px;
    }
  }
}
.mwebTitle {
  @include for-size(phone) {
    padding: 4px 16px !important; //overright modal header
    min-height: 56px;
    h2 {
      font-size: 12px !important;
    }
  }
}
.borderRadiusHide {
  @include border-radius(0 !important);
}
.fullIamgemodalClass {
  > div {
    @include for-size(desktop) {
      width: 450px;
      height: 450px;
      height: 100vh;
      max-height: initial;
      @include border-radius(10px 0 0 10px);
    }
    @include for-size(phone) {
      max-height: 100dvh !important;
      min-height: 300px;
    }
  }
}
.searchLocationTitle {
  @include for-size(phone) {
    height: 56px;
    padding: 10px 16px;
    h2 {
      font-size: 12px !important;
    }
  }
}

.locationBtn {
  margin: 12px 0;
  > span {
    @include flex(center, flex-start, row);
    gap: 8px;
  }
  .icon {
    flex-shrink: 0;
    &.white {
      &::before {
        color: $white;
      }
    }
  }
}
.viewAddressCta {
  @include for-size(desktop) {
    padding: 16px 0 !important;
  }
  @include for-size(phone) {
    padding: 4px 0 !important;
    font-size: 12px !important;
  }
  &:hover,
  &:focus {
    background: transparent !important;
    box-shadow: none !important;
  }
}
.savedAddressHeading {
  line-height: 24px;
  text-align: left;

  @include for-size(desktop) {
    margin: 12px 0;
    font-weight: 700;
    font-size: 18px;
  }
  @include for-size(phone) {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 600;
  }
}
.separator {
  min-height: 32px;
  position: relative;
  width: 100%;
  margin: 16px 0 8px;
  @include flex(center, center, row);
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: $grey3;
    height: 1px;
    width: 100%;
  }
  .text {
    @include flex(center, center, row);
    background: $white;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 12px;
    color: $grey6;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    flex-shrink: 0;
    position: relative;
    z-index: 2;
  }
}
.savedAdd {
  text-align: left;
  font-weight: 700;
  @include for-size(desktop) {
    font-size: 18px;
    line-height: 24px;
    margin: 12px 0;
  }
  @include for-size(phone) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.icon-location_icon:before {
  content: "\eb96";
  color: $pharma6;
}
.icon-ic_gps:before {
  content: "\e97a";
  color: $white;
}
.icon-ic-down_arrow:before {
  content: "\eb87";
  color: #fc9916;
}
.iconBx {
  @include flex(center, flex-start, row);
  height: 20px;
  width: 20px;
  margin-left: 10px;
  flex-shrink: 0;
  position: relative;
  .icon {
    transform: rotate(-90deg);
    font-size: 10px;
    width: 7px;
    position: relative;
    top: 10px;
    position: absolute;
    left: 0;
    &::before {
      color: $pharma6;
    }
    &:last-child {
      left: 8px;
    }
  }
}
.mwebFullHeight {
  height: 100dvh !important;
}
