@use "styles" as *;

.navigationList {
  @include flex(flex-start, unset, row);
  height: 100%;
  @media only screen and (max-width: 600px) {
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    height: 60px;
    background: $white;
    @include box-shadow(0 -2px 10px 0 rgb(0, 0, 0, 0.1));
    z-index: 2;
  }
  li {
    height: 100%;
    width: 98px;
    @media only screen and (max-width: 600px) {
      width: 20% !important;
    }
    a {
      padding: 12px 16px;
      position: relative;
      height: 100%;
      width: 100%;
      cursor: pointer;
      @include flex(flex-start, unset, column);
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 4px solid transparent;
        @media only screen and (max-width: 600px) {
          display: none;
        }
      }
      p {
        font-size: 13px;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0 0 3px;
        padding: 0 0 3px;
        border-bottom: 1px solid $primaryColor;
        display: inline-block;
        @media only screen and (max-width: 600px) {
          border: none;
          font-size: 8px;
          line-height: 10px;
          margin: 0;
          padding: 8px 0 0;
          opacity: 0.6;
          min-height: 20px;
        }
      }
      .subTitle {
        font-size: 12px;
        line-height: 16px;
        opacity: 0.6;
        display: block;
        font-weight: 500;
        @media only screen and (max-width: 600px) {
          display: none;
        }
      }
      .menuicon {
        display: none;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        @media only screen and (max-width: 600px) {
          display: block;
        }
      }
      @media only screen and (max-width: 600px) {
        padding: 10px 4px;
        height: 100%;
        align-items: center;
      }
    }
    &.addOn {
      width: 114px;
      @media only screen and (max-width: 600px) {
        width: 20%;
      }
    }
    &.widthAuto {
      width: auto;
    }
    &.menuTitle {
      width: auto;
      a {
        justify-content: center;
        text-align: center;
      }
      p {
        border: none;
      }
    }
    &.menuHRTitle {
      width: 145px;
    }
    &.active {
      a {
        background-color: $lightGrey;
        @media only screen and (max-width: 600px) {
          background: $primaryColor;
          color: $white;
        }
        &:after {
          border-color: $tertiaryColor;
          @media only screen and (max-width: 600px) {
            display: none;
          }
        }
      }
      p {
        opacity: 1;
      }
      .menuicon {
        span:before,
        &:before {
          color: $white;
        }
      }
    }
    &:first-child {
      display: none;
      @media only screen and (max-width: 600px) {
        display: block;
      }
    }
  }
}
///
.icon-ic_medicines:before {
  content: "\eaae";
  color: #02475b;
}
.icon-ic_account:before {
  content: "\eae7";
  color: #02475b;
}
.icon-ic_tests:before {
  content: "\eae3";
  color: #02475b;
}
.icon-ic_appointments_selected:before {
  content: "\eb4e";
  color: #02475b;
}
.icon-ic-consult:before {
  content: "\e9a2";
  color: #02475b;
}
.icon-ic_myhealth {
  span:nth-child(1) {
    &::before {
      content: "\ea58";
      color: rgb(175, 195, 201);
    }
  }
  span:nth-child(2) {
    &::before {
      content: "\ea59";
      margin-left: -1em;
      color: rgb(2, 71, 91);
    }
  }

  span:nth-child(3) {
    &::before {
      content: "\eae4";
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }

  span:nth-child(4) {
    &::before {
      content: "\eae5";
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }

  span:nth-child(5) {
    &::before {
      content: "\eae6";
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}

.icon-ic_doctors {
  &::before {
    content: "\eade";
    color: #02475b;
  }
}
.icon-ic_covid {
  span:nth-child(1) {
    &:before {
      content: "\ead5";
      color: rgb(0, 38, 49);
    }
  }
  span:nth-child(2) {
    &:before {
      content: "\ead6";
      margin-left: -1em;
      color: rgb(2, 71, 91);
    }
  }
  span:nth-child(3) {
    &:before {
      content: "\ead7";
      margin-left: -1em;
      color: rgb(26, 121, 149);
    }
  }
  span:nth-child(4) {
    &:before {
      content: "\ead8";
      margin-left: -1em;
      color: rgb(0, 38, 49);
    }
  }
  span:nth-child(5) {
    &:before {
      content: "\ead9";
      margin-left: -1em;
      color: rgb(2, 71, 91);
    }
  }
  span:nth-child(6) {
    &:before {
      content: "\eada";
      margin-left: -1em;
      color: rgb(26, 121, 149);
    }
  }
  span:nth-child(7) {
    &:before {
      content: "\eadb";
      margin-left: -1em;
      color: rgb(0, 38, 49);
    }
  }
  span:nth-child(8) {
    &:before {
      content: "\eadc";
      margin-left: -1em;
      color: rgb(2, 71, 91);
    }
  }
  span:nth-child(9) {
    &:before {
      content: "\eadd";
      margin-left: -1em;
      color: rgb(26, 121, 149);
    }
  }
}
.icon-insurance {
  span:nth-child(1) {
    &:before {
      content: "\eb8a";
      color: rgb(2, 71, 91);
    }
  }
  span:nth-child(2) {
    &:before {
      content: "\eb8b";
      margin-left: -1.318359375em;
      color: rgb(2, 71, 91);
    }
  }
  span:nth-child(3) {
    &:before {
      content: "\eb8c";
      margin-left: -1.318359375em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(4) {
    &:before {
      content: "\eb8d";
      margin-left: -1.318359375em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(5) {
    &:before {
      content: "\eb8e";
      margin-left: -1.318359375em;
      color: rgb(2, 71, 91);
    }
  }
  span:nth-child(6) {
    &:before {
      content: "\eb8f";
      margin-left: -1.318359375em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(7) {
    &:before {
      content: "\eb90";
      margin-left: -1.318359375em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(8) {
    &:before {
      content: "\eb91";
      margin-left: -1.318359375em;
      color: rgb(255, 255, 255);
    }
  }
}

.footerNavigationRoot {
  @include flex(stretch, space-between, row);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 78px;
  background: $white;
  z-index: 4;
  padding: 8px 8px 12px;
  @include box-shadow(0 0 10px rgba(0, 0, 0, 0.08));
  overflow-x: hidden;
  .list {
    height: 100%;
    > a {
      @include flex(center, center, column);
      font-size: 10px;
      font-weight: 400;
      color: $grey8;
      .icon {
        @include flex(center, center, row);
        height: 40px;
        width: 40px;
        margin-bottom: 4px;
        position: relative;
        font-size: 22px;
        &::after {
          content: "";
          position: absolute;
          z-index: -1;
          right: 0;
          left: 0;
          bottom: 0;
          height: 100%;
          @include border-radius(8px);
          background: $pharma1;
          opacity: 0;
          transform: scale(0);
          @include transition(all 0.3s ease-in-out);
        }
      }
    }
    &:hover,
    &:focus,
    &.active {
      > a {
        font-weight: 600;
        color: $pharma6;
        .icon {
          &::before {
            color: $pharma6;
          }
          &::after {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
    @include for-size(phone) {
      &:hover {
        > a {
          color: initial;
          .icon {
            &::after {
              @include transform(scale(0));
            }
          }
        }
      }
    }
  }
}
.icon-my-insurance:before {
  content: "\ebc3";
}
