@use "styles" as *;

.dialogContent {
  @include flex(flex-start, unset, row);
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  h4 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    margin: 10px 0 0;
  }
  a {
    font-size: 12px;
    line-height: 20px;
    color: #3d88a0;
    font-weight: 600;
  }
  button {
    background: #106c89;
    width: 160px;
    &:hover {
      background: #106c89;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
.checkbox {
  background: transparent;
  label {
    padding-right: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
.modalContent {
  padding: 16px 24px;
  @media only screen and (max-width: 768px) {
    padding: 16px;
  }
}
.modalBox {
  width: 100% !important;
  border-radius: 0;
  @media only screen and (max-width: 768px) {
    height: 200px;
  }
}

.modal {
  align-items: flex-end !important;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 10px 24px 10px 0;
  @media only screen and (max-width: 768px) {
    margin: 0 0 24px;
    padding: 0;
    flex-wrap: wrap;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    * {
      flex: 1 0 auto;
    }
  }
  span {
    color: #3d88a0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    padding: 0 5px 0 0;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
.pdfViewContainer {
  height: 500px;
}

.consentButton {
  @include flex(center, center, row);
}

.modalTitle {
  @include flex(center, center, row);
  justify-content: flex-end;
  img {
    height: 20px;
  }
}
