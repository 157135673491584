@use "styles" as *;
.icon {
  font-size: 18px;
  flex-shrink: 0;
  margin-right: 8px;
  height: 20px;
  width: 20px;

  &::before {
    color: $grey10;
  }
}
