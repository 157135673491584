@use "styles" as *;

.hamburgerList {
  .list {
    min-height: 52px;
    @include flex(center, flex-start, row);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    width: 100%;
    .arrowIco {
      margin-left: auto;
    }
    .icon,
    .imgIcon {
      margin-right: 8px;
      @include flex(center, center, row);
      height: 16px;
      width: 16px;
      flex-shrink: 0;
      font-size: 16px;
      &::before {
        color: $grey10;
      }
    }
  }
}
.subListMain {
  padding: 0 0 0 22px;
  .subList {
    color: $grey8;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    > a {
      min-height: 36px;
      @include flex(center, flex-start, row);
    }
  }
}

.icon-message {
  &::before {
    content: "\eb71";
    color: #02475b;
  }
}

.icon-userIcon:before {
  content: "\eb92";
  color: #fff;
}

.icon-ic_home:before {
  content: "\eaa7";
  color: #02475b;
}

.icon-ic_arrow_right:before {
  content: "\ea92";
  color: #02475b;
}
.arrowUp {
  transform: rotate(-90deg);
}
.arrowDown {
  transform: rotate(90deg);
}

.icon-ic_home:before {
  content: "\eaa7";
  color: #02475b;
}
.icon-office:before {
  content: "\eb6c";
  color: #01475b;
}
.icon-ic_location:before {
  content: "\eaa9";
  color: #02475b;
}

.icon-ic_delete:before {
  content: "\e970";
  color: #03475a;
}
.icon-memberships {
  font-size: 20px !important;
  &::before {
    content: "\eb9a";
  }
}

.icon-help_icon {
  font-size: 20px !important;
  &::before {
    content: "\eba0";
  }
}

.icon-labTest:before {
  content: "\eb8b";
  color: #228a87;
}
.icon-medicineIco:before {
  content: "\eb8c";
  color: #228a87;
}
.icon-consult:before {
  content: "\eb8d";
  color: #228a87;
}
.icon-mobileHome:before {
  content: "\eb8e";
  color: #228a87;
}
.icon-icon_symptoms:before {
  content: "\ebba";
}
.icon-icon_articles:before {
  content: "\ebbb";
}
.icon-icon-contact:before {
  content: "\ebbc";
}
.icon-Privacy_Policy:before {
  content: "\ebbd";
}
.icon-Return_Policy:before {
  content: "\ebbe";
}
.icon-Terms_Conditions:before {
  content: "\ebbf";
}
