@use "styles" as *;

.profileContainer {
  .userAccount {
    width: 48px;
    height: 48px;
    @include border-radius(50%);
    background: $primaryColor;
    @include flex(center, center, row);
  }
  > .icon {
    cursor: pointer;
    &::before {
      color: $grey10;
      @include for-size(phone) {
        color: #fafbf9;
      }
    }
  }
  .userFirstCharacter {
    background: $brandColor6;
    height: 30px;
    width: 30px;
    @include border-radius(50%);
    @include flex(center, center, flex);
    color: $white;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    @include for-size(phone) {
      color: $pharma10;
      background: $pharma2;
      width: 32px;
      height: 32px;
    }
  }
}

.profileSideBar {
  position: relative;
  z-index: 2;
  .intentOpacityDiv {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($black, 0.6);
    z-index: 1;
  }
}

.accountRoot {
  position: relative;
}
.modalBackground {
  position: fixed;
  background: rgba($black, 0.6);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.userAccountDetails {
  background: $white;
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  overflow: hidden;
  z-index: 10;
  @include box-shadow(0 2px 10px 0 rgb($black, 0.1));
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @include transition(all 0.4s ease-in-out);
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: #e3e2e2;
  }
  &.userListActive {
    width: 380px;
    @include for-size(phone) {
      width: 300px;
    }
  }
  .userDetails {
    padding: 10px 16px;
    text-align: left;
    position: relative;
    @include for-size(desktop) {
      position: sticky;
      top: 0;
      background: $white;
    }
    .profileNameSection {
      @include flex(center, space-between, row);
      position: relative;
      padding: 10px 0;
      @include for-size(desktop) {
        padding: 20px 0 10px;
        margin-bottom: 10px;
      }
      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        color: $black;
      }
      .closeIcon {
        height: 24px;
        width: 24px;
        cursor: pointer;
        color: $black;
      }
    }
    > p {
      line-height: 20px;
    }
    .subTxt {
      font-weight: 400;
      &.uhd {
        text-transform: uppercase;
      }
      @include for-size(desktop) {
        font-size: 14px;
      }
      @include for-size(phone) {
        font-size: 12px;
      }
      .tag {
        margin-left: 8px;
      }
    }
  }
}
.userAccountList {
  padding: 0 16px;
  margin: 0;
  list-style: none;
  text-align: left;
  > li {
    border-bottom: 1px solid rgba(2, 71, 91, 0.3);
    min-height: 48px;
    color: $grey10;
    a,
    .signOut {
      padding: 8px 0;
      font-size: 14px;
      font-weight: 400;
      @include flex(center, space-between, row);
      min-height: 48px;
      user-select: none;
      cursor: pointer;
      color: $grey10;
      span {
        display: flex;
        align-items: center;
      }
    }
    .signOut {
      justify-content: unset;
      img {
        margin: 0 15px 0 0;
      }
    }

    .leftBx {
      @include flex(center, flex-start, row);
      .icon {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        @include flex(center, center, row);
        flex-shrink: 0;
        > img {
          @include imageResponsive;
        }
      }
      img {
        padding: 2px;
      }
    }
    &.logOut {
      @include flex(center, space-between, row);
      cursor: pointer;
      .leftBx {
        cursor: pointer;
      }
    }
  }
}

.downloadOptions {
  @include flex(center, space-between, row);
  padding: 20px 16px;
  margin-top: auto;
  .item {
    height: 35px;
    width: calc(50% - 10px);
    @include border-radius(6px);
    @include flex(center, center, row);
    > span {
      font-size: 42px;
      @include for-size(phone) {
        font-size: 36px;
      }
    }
  }
}

.icon-userIcon:before {
  content: "\eb92";
  @include for-size(phone) {
    color: #fff;
  }
}
.icon-ic_cross:before {
  content: "\e96f";
  color: #02475b;
}
.icon-manage_family:before {
  content: "\eb98";
}
.icon-health_insurance:before {
  content: "\eb99";
}
.icon-memberships:before {
  content: "\eb9a";
}
.icon-logout:before {
  content: "\eb9d";
}
.icon-payments_ico:before {
  content: "\eb9b";
}
.icon-order:before {
  content: "\eb9e";
}
.icon-help_icon:before {
  content: "\eba0";
}
.icon-ic_arrow_right:before {
  content: "\ea92";
  color: #02475b;
}
